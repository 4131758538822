export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">
        Yenilənmiş Bonuslar və Promosyonlar bölməsi
      </p>
      <p className="new__description">
        • Xəritədə ən yaxın Filial, ATM və ya Terminalın yeri əlavə edilib.
      </p>
      <p className="new__description">
        • Face ID və Touch ID üçün əlavə dəstək.
      </p>
      <p className="new__description">
        • Bəzi Android 10 istifadəçiləri üçün təkan bildirişləri ilə bağlı səhv
        düzəldildi.
      </p>
      <p className="new__description">
        • Parametrlərdə mətnin Android istifadəçiləri üçün çox kiçik olması
        problemi həll edildi.
      </p>
    </div>
  );
};
